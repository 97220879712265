import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Wedera"
              description="I developed a NextJS website with DaisyUI and Tailwind, using Firebase for authentication and database, where users can log in, choose photographers or makeover artists for weddings, and book them."
              ghLink=""
              demoLink="https://wedera.in/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="MedAid Disha"
              description="I developed a web-based chatbot for mood analysis and mental health support using ExpressJS and NodeJS, offering consultant calls via Twilio, support in 102 languages via Azure Language Translator, and responses powered by GEMINI."
              ghLink="https://github.com/Swapnendu003/chatu"
              demoLink="https://disha-chatbot.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Prepify"
              description="Prepify optimizes the platform based on user preferences, providing in-depth articles, a 3D AI teacher (Prepia) by Fireworks AI, and a job interview preparation feature with live questions and scoring; the prototype won second prize at Hack4Bengal 3.0."
              ghLink="https://github.com/Swapnendu003/prepify-client-side"
              demoLink="https://prepify-client-side.vercel.app/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="CSE Department Dashboard"
              description="I developed a website for RCC IIT's CSE faculty to submit research works, featuring an admin dashboard for viewing, downloading records, sending reminders, and managing users. The backend is built with ExpressJS and MongoDB, supporting full CRUD operations and JWT-based authentication."
              ghLink=""
              demoLink="https://cse-dept-dashboard.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
